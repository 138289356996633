const APIBaseURL = process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'http://localhost:8000/api/' : 'https://api.tradessly.com/api/';

const NetworkingProvider = {

    async fetchData(url, method, requestBody) {

        const formattedURL = APIBaseURL + url

        try {

            const options = {
                method: method,
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json'
                }
            };

            if (requestBody) {
                options.body = JSON.stringify(requestBody);
            }

            const response = await fetch(formattedURL, options);

            const json  = await response.json()

            if (response.ok) {
                return json;
            } else {
                console.log('Failed to fetch data:', response.statusText);
                return json;
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

};

export default NetworkingProvider;
