import React, {Children, useEffect, useLayoutEffect, useRef, useState} from 'react';
import { ZoomMtg } from "@zoom/meetingsdk";
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import ReactDOM from "react-dom/client";

const ZoomComponent = ({ apiKey, apiSecret, meetingId, password, username }) => {

    const size = useWindowSize();

    const [initialized, setInitialized] = useState(false);

    const mutationCallback = function(mutationsList, observer) {
        for(const mutation of mutationsList) {
            identifyDivs() //
        }
    };

    const observer = new MutationObserver(mutationCallback);

    async function setupZoom() {
        try {

            const client = ZoomMtgEmbedded.createClient()

            let meetingSDKElement = document.getElementById('zm-zoom-wrapper-root') || undefined;

            if (!meetingSDKElement) {
                return console.log('No meetingSDKElement found');
            }

            await client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US', customize: { video: { isResizable: false } } })

            observer.observe(meetingSDKElement, { childList: true, subtree: true});

            const newSignature = ZoomMtg.generateSDKSignature({
                sdkKey: apiKey,
                sdkSecret: apiSecret,
                meetingNumber: meetingId,
                role: '0',
                success: function (res) {
                    // console.log('Signature', res)
                },
                error: function (res) {

                }
            })

            // Join Zoom meeting
            await client.join({
                meetingNumber: meetingId,
                userName: username, // Make sure to replace with the actual user name
                signature: newSignature,
                sdkKey: apiKey,
                password: password,
                success: function (res) {
                    console.log('join meeting success');
                    ZoomMtg.showInviteFunction({show: false});
                },
                error: function (res) {
                    console.log(res);
                }
            });

            client.off()

        } catch (error) {
            console.error(error);
            identifyDivs()
        }
    }

    function identifyDivs() { // react-draggable
        const reactDraggableDiv = document.getElementsByClassName('react-draggable');
        if (reactDraggableDiv.length > 0) {
            for (let i = 0; i < reactDraggableDiv.length; i++) {
                const div = reactDraggableDiv[i]
                div.style.height = "100%"
                div.style.width = "100%"
                div.position = 'relative'
                div.draggable = 'false'
                div.style.draggable = 'false'
                div.style.touchAction = 'none'
            }
            // console.log('Found reactDraggableDiv', reactDraggableDiv)
        }
        //zmwebsdk-MuiBox-root

        const screenShareID = '__ZOOM_SDK_SHARE_CANVAS'
        const selfVideoID = 'ZOOM_WEB_SDK_SELF_VIDEO'
        const containsScreenShare = document.querySelector(`[id*="__ZOOM_SDK_SHARE_CANVAS"]`) !== null;
        const containsSelfVideo = document.querySelector(`#${selfVideoID}`) !== null;

        // console.log('containsSelfVideo', containsSelfVideo)
        // console.log('containsScreenShare', containsScreenShare)

        const webUIBox = document.getElementsByClassName('zmwebsdk-MuiBox-root');
        if (webUIBox.length > 0) {
            for (let i = 0; i < webUIBox.length; i++) {
                const div = webUIBox[i]
                div.style.height = "100%"
                div.style.width = "100%"
                div.position = 'relative'
                div.draggable = 'false'
                div.style.draggable = 'false'
                div.style.touchAction = 'none'
            }
            // console.log('Found webUIBox', webUIBox)
        }

        const parent_1 = document.getElementsByClassName('zmwebsdk-makeStyles-videoCustomize-1');
        if (parent_1.length > 0) {
            for (let i = 0; i < parent_1.length; i++) {
                const div = parent_1[i]
                div.style.height = "100%"
                div.style.width = "100%"
                div.position = 'relative'
                div.draggable = 'false'
                div.style.draggable = 'false'
                div.style.touchAction = 'none'
            }
            // console.log('Found parent_1', parent_1)
        }
        const parent_3 = document.getElementsByClassName('zmwebsdk-MuiPaper-root')
        if (parent_3.length > 0) {
            for (let i = 0; i < parent_3.length; i++) {
                const div = parent_3[i]
                div.style.height = "100%"
                div.style.width = "100%"
                div.position = 'relative'
                div.style.borderRadius = '0px'
            }
        }
        // console.log('parent_3', parent_3)

        const parent_4 = [ // react-resizable-handle
            ...document.querySelectorAll(`[id*="suspension-view-tabpanel"]`),
        ];
        if (parent_4.length > 0) {
            for (let i = 0; i < parent_4.length; i++) { //
                const div = parent_4[i]
                div.style.height = "100%"
                div.style.width = "100%"
                div.position = 'relative'
                div.draggable = 'false'
                div.style.draggable = 'false'
                div.style.touchAction = 'none'
            }
        }
        // ZOOM_WEB_SDK_SELF_VIDEO
        const containsScreenshare = false;

        const toolbars = [
            ...document.querySelectorAll(`[class*="MuiToolbar-root"]`),
        ]
        if (toolbars.length > 0) {
            for (let i = 0; i < toolbars.length; i++) {
                let div = toolbars[i]
                div.style.height = "100vh"
                div.style.width = '100vw'
                div.style.display = 'none'
            }
        }
        // console.log('Found toolbars', toolbars);

        const muiRoots = [
            ...document.querySelectorAll(`[class*="MuiPaper-root"]`),
        ]
        if (muiRoots.length > 0) {
            for (let i = 0; i < muiRoots.length; i++) {
                let div = muiRoots[i]
                const className = 'MuiButton-startIcon'
                const canvasID = 'zoom-sdk-video-canvas'
                const containsButtons = div.querySelector(`.${className}`) !== null;
                const containsCanvas = div.querySelector(`#${canvasID}`) !== null;

                // const contains = (div.current.children !== null ? div.current.children : []).includes((item) => item.classList.contains('MuiButtonBase-root'))//.contains('MuiButtonBase-root')
                // console.log('containsButtons', containsButtons, i)
                // console.log('containsCanvas', containsCanvas, i)

                if (containsCanvas) {
                    div.style.height = "100vh"
                    div.style.width = '100vw'
                    div.style.position = 'absolute'
                    div.style.top = 0
                    // div.display = 'none'
                } else {
                    div.style.display = 'none'
                }
            }
        }
        // console.log('Found muiRoots', muiRoots);

        const listItems = [
            ...document.querySelectorAll(`[class*="MuiListItem-root"]`),
        ]
        if (listItems.length > 0) {
            for (let i = 0; i < listItems.length; i++) {
                let div = listItems[i]
                div.style.minHeight = "100vh"
                div.style.minWidth = '100vw'
                div.style.height = "100vh"
                div.style.width = '100vw'
                div.style.left = 0;
                div.style.top = 0;
            }
        }

        const screenShareCanvas = [
            ...document.querySelectorAll(`[id*="__ZOOM_SDK_SHARE_CANVAS"]`),
        ]
        if (screenShareCanvas.length > 0) {
            for (let i = 0; i < screenShareCanvas.length; i++) {
                let div = screenShareCanvas[i]
                div.style.height = "100vh"
                // div.style.width = '100vw'
            }
        }
        // console.log('Found screenShareCanvas', screenShareCanvas);

        const muiListItems = [
            ...document.querySelectorAll(`[class*="MuiListItem-root"]`),
        ]
        if (muiListItems.length > 0) {
            for (let i = 0; i < muiListItems.length; i++) {
                let div = muiRoots[i]
                div.style.top = '0px'
                div.style.left = '0px'
                div.style.height = "100vh"
                div.style.width = '100vw'
            }
        }
        console.log('Found muiListItems', muiListItems);

        // dataset selectors
        const parent_5 = [
            ...document.querySelectorAll(`[class^="zmwebsdk-makeStyles-root"]`),
        ];

        // console.log('parent_5', parent_5)
        if (parent_5.length > 0) {
            let containsSharing = false
            if (parent_5.some((div) => div.className.includes('inSharing') === true)) {
                console.log('inSharing')
                containsSharing = true
            } else {
                console.log('no inSharing')
                containsSharing = false
            }
            for (let i = 0; i < parent_5.length; i++) {
                const div = parent_5[i]
                if (containsSharing && div.className.includes('inSharing')) {
                    div.style.position = 'absolute';
                    div.style.width = "100%";
                    div.style.left = '0px';
                    console.log('Found div', div)
                }

                if (containsSharing && div.className.includes('inSharing') === false) {
                    if (div.nodeName === 'LI') {
                        div.style.width = "15%"
                        div.style.position = '';
                        div.style.right = '0px';
                        div.style.left = 'unset';
                        div.style.display = 'none'
                    } else {
                        // div.style.position = 'relative'
                        div.style.width = "100%";
                        div.style.right = '0px';
                        div.style.left = 'unset'
                    }
                }

                if (!containsSharing) {
                    div.style.display = ''
                    if (div.nodeName === 'LI') {
                        div.style.left = 'unset';
                    }
                    div.style.width = "100%"
                    // div.style.left = 'unset';

                }

                div.style.height = "100%"
                div.draggable = 'false'
                div.style.draggable = 'false'
                div.style.touchAction = 'none'
                div.style.top = '0px';

            }
        }
        const parent_6 = [
            ...document.querySelectorAll(`[class*="zmwebsdk-makeStyles-wrap"]`),
        ];
        if (parent_6.length > 0) {
            for (let i = 0; i < parent_6.length; i++) {
                const div = parent_6[i]
                div.style.height = "100%"
                div.style.width = "100%"
                div.draggable = 'false'
                div.style.draggable = 'false'
                div.style.touchAction = 'none'
                // div.position = 'relative'
            }
        }
        const parent_7 = [ // react-resizable-handle
            ...document.querySelectorAll(`[class*="react-resizable-handle"]`),
        ];
        if (parent_7.length > 0) {
            for (let i = 0; i < parent_7.length; i++) { //
                const div = parent_7[i]
                div.style.display = "none"
            }
        }


        //MuiTypography-root
        const typographyRoote = [ // react-resizable-handle
            ...document.querySelectorAll(`[class*="MuiTypography-root"]`),
        ];
        if (typographyRoote.length > 0) {
            for (let i = 0; i < typographyRoote.length; i++) { //
                const div = typographyRoote[i]
                div.style.display = "none"
            }
        }

        const topbarClass = document.getElementsByClassName("zmwebsdk-MuiToolbar-root")
        if (topbarClass.length > 0) {
            for (let i = 0; i < topbarClass.length; i++) {
                const div = topbarClass[i]
                div.style.display = "none"
                div.draggable = 'false'
                div.style.draggable = 'false'
                div.style.touchAction = 'none'
            }
            setInitialized(true)
        }

        const muiBoxRoots = [
            ...document.querySelectorAll(`[class*="MuiBox-root"]`),
        ]
        if (muiBoxRoots.length > 0) {
            for (let i = 0; i < muiBoxRoots.length; i++) {
                let div = muiBoxRoots[i]
                const screenshareID = '__ZOOM_SDK_SHARE_CANVAS'
                const selfVideoID = 'ZOOM_WEB_SDK_SELF_VIDEO' // zoom-sdk-video-canvas
                const selfVideoID_ = 'zoom-sdk-video-canvas' // zoom-sdk-video-canvas
                const containsScreenshare = div.querySelector(`#${screenshareID}`) !== null;
                const containsScreenshare_ = div.querySelector(`#${selfVideoID_}`) !== null;
                const containsSelfVideo = div.querySelector(`#${selfVideoID}`) !== null;

                div.style.minHeight = '100vh';
                div.style.position = 'absolute'
                div.style.top = 0
                div.style.right = null
                div.style.left = 0
                div.style.minWidth = '100vw'

                if (div.children.length > 0) {
                    const childDiv = div.children[0];
                    // console.log('CLASSESS:', childDiv, childDiv.classList)
                    if (childDiv.classList.contains('MuiSvgIcon-root')) {
                        div.style.display = 'none'
                    }
                    if (childDiv.children.length > 0) {
                        const innerChildDiv = childDiv.children[0]
                        // console.log('INNER:', innerChildDiv)
                        // div.style.display = 'none'
                    }
                }

                if (div.children.length > 0) {
                    const containsTargetScreenShare = div.children[0].id.includes(screenshareID)
                    const containsTargetSelfVideo = div.children[0].id.includes(selfVideoID)
                    const containsTargetSelfVideo_ = div.children[0].id === selfVideoID_
                    if (containsTargetScreenShare) {
                        // console.log('XXXXX', i, div)
                        div.style.position = 'absolute'
                        div.style.top = 0
                        div.style.right = 0
                        div.style.left = 0
                        div.style.width = '100%'
                        div.style.maxWidth = '100%'
                    }
                    if (containsTargetSelfVideo || containsTargetSelfVideo_) {
                        if (containsScreenshare === false && containsScreenshare_ === false) {
                            // console.log('XXX', i, div)
                            div.style.position = 'absolute'
                            div.style.top = 0
                            div.style.right = 0
                            div.style.left = 0
                            div.style.width = '100%'
                        } else {
                            // console.log('YYY', i, div)
                            div.style.position = 'absolute'
                            div.style.top = 0
                            div.style.right = 0
                            div.style.left = 0
                            div.style.width = '100%'
                            div.style.maxWidth = '100%'
                        }

                    }
                }
            }
        }
    }

    useEffect(() => {
        setupZoom();
    }, []);

    useEffect(() => {
        identifyDivs()
    }, [size]);

    return (
        <div id={'zmm'} className={'zm-zoom-wrapper'} style={{ maxHeight: "100vh", height: '100vh', overflow: 'hidden'}}>
            <div className={'zm-zoom-wrapper-content'} style={{ maxHeight: "100vh", height: '100vh', overflow: 'hidden'}}>
                <div className={'zm-zoom-wrapper-root'} id="zm-zoom-wrapper-root" style={{ maxHeight: "100vh", height: '100vh', overflow: 'hidden'}}>
                    {/* Zoom meeting UI will be rendered here */}
                </div>
            </div>
        </div>
    )
};

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default ZoomComponent;
