import logo from './logo.svg';
import './App.css';
import SessionView from "./pages/sessionview/SessionView";

function App() {
  return (
      <>
      <div id={'main_app'} className="App">
          <div style={{maxHeight: '100vh', height: '100vw', width: '100%', backgroundColor: 'black', overflow: 'hidden'}}>
              <SessionView />
          </div>
      </div>
    </>
)
}

export default App;
