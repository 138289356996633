import ZoomComponent from "./components/ZoomComponent";
import {useEffect, useState} from "react";
import NetworkingProvider from "../../providers/NetworkingProvider";
import CryptoJS from 'crypto-js';

const SessionView = (privateKey, buffer) => {


    const [password, setPassword] = useState();
    const [meetingId, setMeetingId] = useState();
    const [username, setUsername] = useState()
    const [initialized, setInitialized] = useState(false);

    async function validateUserToken() {

        // console.log(CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex));
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (!token) {
            return;
        }
        try {
            const payload = {
                token: token//sessionTokenData.token ?? '',
            }
            const dataResponse = await NetworkingProvider.fetchData('trading-sessions/session-connect/validate', 'POST', payload);
            // console.log(dataResponse);
            const data = dataResponse.data;
            if (data ) {
                const iv = data.iv ?? '';
                const meeting_id_value = data.meeting_id
                const meeting_password_value = data.meeting_password;
                const meeting_username = data.meeting_username;
                // console.log('meeting_id_value', meeting_id_value)
                // console.log('meeting_password_value', meeting_password_value)
                const decryptedMeetingId = decrypt(meeting_id_value, iv)
                const decryptedMeetingPassword = decrypt(meeting_password_value, iv)
                const decryptedUsername = decrypt(meeting_username, iv)
                // console.log('decryptedMeetingId', decryptedMeetingId)
                // console.log('decryptedUsername', decryptedUsername);

                setMeetingId(decryptedMeetingId)
                setPassword(decryptedMeetingPassword)
                setUsername(decryptedUsername)
                setInitialized(true)
                // setSessionTokenData(data)
            } else {
                console.log('Failed to fetch data');
            }
        } catch(error) {
            console.log(error);
        }
    }

    function decrypt(encryptedData, iv) {
        const key = process.env.REACT_APP_SESSION_KEY ?? ''
        const bytes = CryptoJS.AES.decrypt(encryptedData, key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    async function fetchLiveStreamDetails() {
        const urlParams = new URLSearchParams(window.location.search);
        const userId = urlParams.get('user');
        const sessionId = urlParams.get('session');
        if (!sessionId || !userId) {
            return;
        }
        console.log('User:', userId);
        console.log('Session:', sessionId);

        const payload = {
            userId: userId,
            sessionId: sessionId,
        }

        try {
            const dataResponse = await NetworkingProvider.fetchData('trading-sessions/session-connect', 'POST', payload);
            const data = dataResponse.data;
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        // getSignature(null)
        // fetchLiveStreamDetails()
        validateUserToken()
    },[initialized]);

    return (
        <>
            { initialized && (
                <ZoomComponent meetingId={meetingId} apiKey={'TRm1xNM9S16Lo6nP5cZLxA'} apiSecret={'axbgNs0HtJcnZBVr7lp63bXKr9IqpwyH'} password={password} username={username} />
            )}
        </>
    )
}

export default SessionView
